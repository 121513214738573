@import '../../../../scss/theme-bootstrap';

$header-bottom-padding: 55px;
$arrow-controls-height: 64px;
$slick-dot-color: $color--white;
$slick-dot-color-active: $color--white;
$slick-dot-size: 6px;
$slick-opacity-default: 1;
$slick-opacity-on-hover: 0.75;
$slick-opacity-not-active: 0.3;

.virtual-events-carousel-formatter__inner {
  margin: 0 auto;
  overflow: hidden;
}

.virtual-events-carousel-formatter__wrapper {
  .virtual-events-carousel-formatter {
    color: $color--white;
    &__header-text {
      color: $color--white;
      @include swap_direction(padding, 30px 0 20px);
      @include breakpoint($bp--large-up) {
        @include swap_direction(padding, 80px 0 $header-bottom-padding);
      }
      p {
        margin: 0;
      }
      .content-block__line--content {
        @include breakpoint($bp--large-up) {
          padding: 20px 0 0;
        }
      }
    }
  }
  &.virtual-events-carousel-formatter {
    position: relative;
    width: 100%;
    color: $color--white;
    .carousel-controls {
      position: absolute;
      width: 120px;
      top: calc((#{$header-bottom-padding} + #{$arrow-controls-height}) * -1);
      #{$rdirection}: 0;
      height: $arrow-controls-height;
      line-height: $arrow-controls-height;
      .slick-prev,
      .slick-next {
        width: 48px;
        height: 48px;
        opacity: 1;
        top: 10px;
        margin-top: 0;
        overflow: hidden;
      }
      .slick-prev {
        // EMEABEX-1675 - Slick RTL is disabled due to lack of support
        left: 0;
        background: url('#{$base-theme-path}svg-icons/src/icon--arrow-left-lrg--white.svg')
          no-repeat;
      }
      .slick-next {
        // EMEABEX-1675 - Slick RTL is disabled due to lack of support
        right: 0;
        background: url('#{$base-theme-path}svg-icons/src/icon--arrow-right-lrg--white.svg')
          no-repeat;
      }
      .slick-prev,
      .slick-next {
        &:before {
          content: unset;
        }
        &:hover,
        &:focus,
        &:active {
          &:before {
            color: $color--white;
          }
        }
      }
    }
    .carousel-dots {
      text-align: center;
      margin: 15px 0;
      .slick-dots {
        bottom: 19.5px;
        > li {
          position: relative;
          display: inline-block;
          height: $slick-dot-size;
          width: $slick-dot-size;
          margin: 0 5px;
          padding: 0;
          cursor: pointer;
          button {
            border: 0;
            background: transparent;
            display: block;
            height: $slick-dot-size;
            width: $slick-dot-size;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover,
            &:focus {
              outline: none;
              &:before {
                opacity: $slick-opacity-on-hover;
              }
            }
            &:before {
              width: 9px;
              height: 9px;
              background: $color--white;
              border-radius: 50%;
              position: absolute;
              top: 0;
              #{$ldirection}: 0;
              content: '';
              width: $slick-dot-size;
              height: $slick-dot-size;
              font-size: $slick-dot-size;
              line-height: $slick-dot-size;
              text-align: center;
              color: $slick-dot-color;
              opacity: $slick-opacity-not-active;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
          }
          &.slick-active button:before {
            background: $color--white;
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
            .virtual-events-carousel-formatter__wrapper & {
              background: $color--white;
            }
          }
        }
      }
    }
    &--slide-peeking {
      overflow: hidden;
      .slick-list {
        width: 100%;
        overflow: hidden;
        padding-#{$rdirection}: 20%;
        margin-#{$ldirection}: 0;
        margin-#{$rdirection}: auto;
      }
    }
    &--allow-overflow {
      overflow-x: clip;
      overflow-y: visible;
      z-index: 99;
      .virtual-events-carousel-formatter__inner,
      .slick-list {
        overflow-x: clip;
        overflow-y: visible;
      }
    }
  }
}
